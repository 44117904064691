import { ethers } from './ethers-5.1.esm.min.js'
let provider,signer,conContract,eptContract,arrContract,attContract,usdtContract,attNewContract,attConContract,chargeContract
    ,orderContract,btcLpContract,btcConContract,tradeContract,att2Contract,att2ChargeContract;
try {
     provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
     signer = provider.getSigner()
     conContract = new ethers.Contract(contractConfig.conAddress,conAbi, signer);
     eptContract = new ethers.Contract(contractConfig.eptAddress,eptAbi, signer);
     arrContract = new ethers.Contract(contractConfig.arrAddress,arrAbi, signer);
     attContract = new ethers.Contract(contractConfig.attAddress,attAbi, signer);
     usdtContract = new ethers.Contract(contractConfig.usdtAddress,usdtAbi, signer);
     attNewContract = new ethers.Contract(contractConfig.attNewAddress,attnewAbi, signer);
     attConContract = new ethers.Contract(contractConfig.attConAddress,attconAbi, signer);
     chargeContract = new ethers.Contract(contractConfig.chargeConAddress,chargeConAbi, signer);
    orderContract = new ethers.Contract(contractConfig.orderAddress,orderAbi, signer);
    btcLpContract = new ethers.Contract(contractConfig.btclp,btcLpAbi, signer);
    btcConContract = new ethers.Contract(contractConfig.btclpCon,btcConAbi, signer);
    tradeContract = new ethers.Contract(contractConfig.tradeCon,tradeAbi, signer);
    att2Contract = new ethers.Contract(contractConfig.att2Address,att2Abi, signer);
    att2ChargeContract = new ethers.Contract(contractConfig.att2ChargeAddress,att2ChargeAbi, signer);
} catch (e) {

}
// function to check if metamask is installed
var isMetamaskInstalled = () => ethereum.isMetamaskInstalled

// function to check if metamask is connected to the current chain
var isMetamaskConnected = () => ethereum.isConnected()

// function to enable metamask if its disconnected
// const enableMetamask = async () => {
//     await ethereum.on('connect', (chainId) => {
//         console.log({ chainId })
//         console.log('Metamask Connected:', ethereum.isConnected())
//     })
// }

// function to get metamask chainID
const getChainId = async () => {
    return await ethereum.request({method: 'eth_chainId'})
}

// function to get metamask networkId
const getNetworkId = async () => {
    return await ethereum.request({method: 'net_version'})
}

// function to get metamask account connected with dapp
const getAccount = async () => {
    try {
        let account = await ethereum.request({method: 'eth_accounts'})
        return account
    } catch (error) {
        return error
    }
}

// function to request metamask to connect with account
const connectToAccount = async () => {
    try {
        let account = await ethereum.request({method: 'eth_requestAccounts'})
        return account
    } catch (error) {
        return error
    }
}

// function to get the balance of the connected account
const getBalance = async () => {
    try {
        let account = await getAccount()
        if (account.length === 0) {
            return 'Connect to account first!'
        }
    
        let balance = await signer.getBalance()
        return ethers.utils.formatEther(balance) + ' ETH'
    } catch (error) {
        return error
    }
}

//调用NFT
const createNft = async () => {
    try {
        const gasPrice = await provider.getGasPrice();
        let tx = await conContract.SellNftCancel(1,{
            gasPrice: gasPrice,
            gasLimit:800000,
        });
        if(tx){
            setTimeout(function () {
                location.reload();
            },3000)
        }
    } catch (error) {
        return error
    }
}

//查询
const lookNft = async () =>{
    try {
        await conContract.Uaddress().then( re => {
        }).catch(err => {
            return;
        });
    } catch (error) {
        return error
    }
}

export default {
    provider,
    conContract,
    eptContract,
    arrContract,
    attContract,
    usdtContract,
    attNewContract,
    attConContract,
    chargeContract,
    orderContract,
    btcLpContract,
    btcConContract,
    tradeContract,
    att2Contract,
    att2ChargeContract,
    signer,
    isMetamaskInstalled,
    isMetamaskConnected,
    getChainId,
    getNetworkId,
    getAccount,
    connectToAccount,
    getBalance,
}